<template>
  <div class="col-12 mb-4">
    <div class="row justify-content-center justify-content-md-start text-center text-md-start">
      <div class="col-12 bold font17 normal-text-dark pe-0">
        {{ stepNumber }}. Select Appeal:
      </div>
      <div class="col-12 mt-3">
        <div class="row">
          <div class="col-12 col-md-auto " v-for="(child, key) in childProjects" :key="key">
            <Button @click="selectChildProject(child)" class="btn bold mb-2 w-100" :class="{green: selectedChildProjectValue === child, lightgrey_gold: selectedChildProjectValue !== child}" :btnText="btnText(child)">
              {{child.description}}<span v-if="child.fixed"> ({{currency}}{{child.cost}})</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue'))
  },
  props: ['selectedChildProject', 'childProjects', 'currency', 'stepNumber'],
  emits: ['update:selectedChildProject'],
  name: 'Project Donate Select Appeal',
  computed: {
    selectedChildProjectValue: {
      get () {
        return this.selectedChildProject
      },
      set (val) {
        this.$emit('update:selectedChildProject', val)
      }
    }
  },
  methods: {
    selectChildProject (val) {
      this.selectedChildProjectValue = val
    },
    btnText (val) {
      let ret = `${val.description}`
      if (val.fixed) {
        ret = `${val.description} (${this.currency}${val.cost})`
      }
      return ret
    }

  }
}
</script>
